import React, { useState } from "react"
import Title from "../components/Title"
import Layout from "../components/Layout"
import Expandable from "../components/Expandable"
import Helmet from "react-helmet"
import AcceptanceCriterion from "../components/AcceptanceCriterion"
import PlainAcceptanceCriterion from "../components/PlainAcceptanceCriterion"

import { BsArrowBarRight } from "@react-icons/all-files/bs/BsArrowBarRight"
import { BsArrowReturnRight } from "@react-icons/all-files/bs/BsArrowReturnRight"
import { MdPauseCircleOutline } from "@react-icons/all-files/md/MdPauseCircleOutline"
import { MdHistory } from "@react-icons/all-files/md/MdHistory"
import { CgChevronDoubleRightO } from "@react-icons/all-files/cg/CgChevronDoubleRightO"
import { FiKey } from "@react-icons/all-files/fi/FiKey"
import { IoIosPeople } from "@react-icons/all-files/io/IoIosPeople"
import { graphql } from "gatsby"
import TreeModel from "tree-model"
import BHDLogo from "../images/BHD-Logo.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useQueryParam } from "gatsby-query-params";


import { getSearchParams } from "gatsby-query-params";

const Bubble = ({ children, icon, heading }) => {
  return (
    <div className="border-2 border-bhd-blue rounded p-3 pt-0 flex flex-col">
      <div className="relative">
        <div className="text-xl my-2 text-bhd-blue font-medium">{heading}</div>
        <div className="absolute right-0 top-2">{icon}</div>
      </div>
      {children}
    </div>
  )
}
/* bg-gray-200 bg-gray-300 bg-gray-400 bg-gray-500 bg-gray-600 bg-gray-700 */
/* border-gray-200 border-gray-300 border-gray-400 border-gray-500 border-gray-600 border-gray-700 */

const buildDependencies = criteria => {
  for (let criterion of criteria) {
    let found = { when: "" }
    if (criterion.dependant) {
      found = criteria.find(x => x.id == criterion.dependant)
    }
    const phrases = [found?.given, found?.when, criterion.given]

    const usedPhrases = phrases.flat().filter(x => x && x.length > 0)
    criterion.given = usedPhrases
  }
  return criteria
}

const buildTree = criteria => {
  const treeCriteria = criteria.filter(
    c => c.dependant === "" || c.dependant === undefined || c.dependant === null
  )
  const root = treeCriteria
  const dependants = criteria.filter(
    c => c.dependant !== "" && c.dependant !== null && c.dependant !== undefined
  )
  const toRemove = []

  const findDependingParent = (parent, c) => {
    if (c.dependant === parent?.id) {
      return parent
    }
    if (parent instanceof Array) {
      for (let n of parent) {
        const found = findDependingParent(n, c)
        if (found) {
          return found
        }
      }
    }
    if (parent.children?.length > 0) {
      return findDependingParent(parent.children, c)
    }
    return false
  }

  let count = 0
  while (dependants.length > 0 && count < 1000) {
    count += 1
    for (let criterion of dependants) {
      const parent = findDependingParent(root, criterion)
      if (!parent) {
        console.log("not found..", { criterion, root })
      } else {
        parent["children"] = parent["children"] || []
        parent["children"].push(criterion)
        console.log("added ", criterion.id, " as a child to ", parent.id)
        toRemove.push(criterion)
      }
    }
    for (let rem of toRemove) {
      const index = dependants.indexOf(rem)
      if (index > -1) {
        dependants.splice(index, 1)
      }
    }
  }
  const tree = new TreeModel()
  return tree.parse({ id: "root", children: root })
}

// const renderTree = ({model, children, id, depth=0}) => {
//   return (<div className={`bg-gray-${depth}00 flex flex-col border border-bhd-blue rounded p-2`}>
//     <AcceptanceCriterion {...model} />
//     {children.length > 0 && (
//         <Expandable depth={depth}>
//     <div className="children flex flex-col gap-4">
//       {children.map(child => renderTree({...child, depth: depth+1}))}
//     </div>
//       </Expandable>)}
//     </div>)
// }

const AcceptanceCriteria = ({ data }) => {
  const {
    strapiAcceptanceCriterion: doc,
    allStrapiCriterion: { edges: allCriteria },
  } = data

  const { Criteria: criteria } = doc
  // const builtCriteria = buildDependencies(criteria)

  // const builtTree = buildTree(criteria)

  // const blocks = builtTree.children.map(c => renderTree(c))

  const blocks = criteria.map(({ id }) => (
    <AcceptanceCriterion
      key={id}
      id={id}
      allCriteria={allCriteria}
      expanded={true}
      givens={[]}

    />
  ))

  const classicBlocks = criteria.map(({ id }) => (
    <PlainAcceptanceCriterion
      key={id}
      id={id}
      allCriteria={allCriteria}
      expanded={true}
      givens={[]}
      depth={0}

    />
  ))

  const classic = useQueryParam('classic', 'false');

  return (
    <Layout>
      <Helmet>
        <title>{doc.Title}</title>
      </Helmet>
      <div className="flex flex-row justify-evenly items-center">
        <div>
          <BHDLogo width="10rem" />
        </div>
        <div>
          <GatsbyImage
            image={getImage(doc.ClientLogo?.localFile)}
            alt={doc.ClientLogo?.alternativeText}
          />
        </div>
      </div>
      <Title>{doc.Title}</Title>
      <div className="flex flex-col gap-4">
        <Bubble heading="Audience" icon={<IoIosPeople size={24} />}>
          {doc.Audience.map(item => (
            <div className="flex flex-row gap-2 items-center" key={item.Rule}>
              <div>
                <BsArrowBarRight size={24} />
              </div>
              <div>{item.Rule}</div>
            </div>
          ))}
        </Bubble>
        <Bubble heading="Legend" icon={<FiKey size={24} />}>
          <div className="flex flex-col items-start">
            <div className="flex flex-row gap-2 items-center">
              <div>
                <MdHistory size={24} />
              </div>
              <div>Given</div>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div>
                <CgChevronDoubleRightO size={24} />
              </div>
              <div>When</div>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div>
                <MdPauseCircleOutline size={26} className="rotate-90" />
              </div>
              <div>Then</div>
            </div>
          </div>
        </Bubble>
        {classic === "true" ? classicBlocks : blocks }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    strapiAcceptanceCriterion(slug: { eq: $slug }) {
      id
      Title
      Audience {
        Rule
      }
      Criteria {
        id
      }
      ClientLogo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
    allStrapiCriterion {
      edges {
        node {
          id
          dependants {
            id
          }
          When
          Title
          Given
          Then
        }
      }
    }
  }
`

export default AcceptanceCriteria
