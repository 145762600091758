import React, { useState } from "react"
import Block from "./Block"
import Heading from "./Heading"
import Expandable from "./Expandable"
import {
  QuestionIcon,
  StopwatchIcon,
  InfoIcon,
  ArchiveIcon,
  ChecklistIcon,
  PeopleIcon,
  ChevronRightIcon,
} from "@primer/octicons-react"
import { IconContext } from "react-icons"
import { BsArrowReturnRight } from "@react-icons/all-files/bs/BsArrowReturnRight"
import { HiOutlineArrowNarrowRight } from "@react-icons/all-files/hi/HiOutlineArrowNarrowRight"
import { MdPauseCircleOutline } from "@react-icons/all-files/md/MdPauseCircleOutline"
import { MdHistory } from "@react-icons/all-files/md/MdHistory"
import { CgChevronDoubleRightO } from "@react-icons/all-files/cg/CgChevronDoubleRightO"

import { BsPlus } from "@react-icons/all-files/bs/BsPlus"
import { MdExpandMore } from "@react-icons/all-files/md/MdExpandMore"
import { MdExpandLess } from "@react-icons/all-files/md/MdExpandLess"
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight"

const findCriteria = (id, allCriteria) => {
  const { node } = allCriteria.find(
    ({ node: criterion }) => criterion.id === id
  )
  return node
}

const PlainAcceptanceCriterion = ({
  id,
  allCriteria,
  parents = [],
  givens = [],
  depth = 0,
}) => {
  const {
    Title: title,
    dependants,
    Given: given,
    When: when,
    Then: then,
  } = findCriteria(id, allCriteria)
  const [expanded, setExpanded] = useState(true)
  const buildGiven = inputPhrases => {
    const phrases = inputPhrases.filter(x => x !== "" && x !== null)
    console.log({phrases})
    if (phrases.join("") === "") {
      return "";
    }
    let result = []
    if (phrases instanceof Array) {
      for (let phrase of phrases) {
        result.push((<>&nbsp;&nbsp;&nbsp;{phrase}<br/></>))
      }
    } else {
      //result.push(phrases)
    }

    return result
    // if (phrases?.length > 2) {
    // } else {
    //   return <div className="gap-2 items-start">{result}</div>
    // }
  }

  const calculatedGiven = buildGiven([...givens, given])
  let titleClasses = "text-lg"
  if (depth === 0) {
    titleClasses = "text-xl mt-8"
  }

  const fullLayout = (
    <div
      className={`flex flex-col`}
    >
      <span>
        <div className={`font-bold ${titleClasses}`}>
          {title}</div>

        {calculatedGiven.toString() !== "" && (<>
      GIVEN <br/>
          {calculatedGiven}

        </>)}
      WHEN {when}<br/>
      THEN {then}
        {dependants.length > 0 && (
          <div className="flex flex-col gap-2 mt-2">
            {dependants.map(({ id }) => (
              <PlainAcceptanceCriterion
                id={id}
                allCriteria={allCriteria}
                parents={[...parents, id]}
                givens={[...givens, given, when]}
                depth={depth + 1}
              />
            ))}
          </div>
        )}
    </span>
    </div>
  )

    return (
      <div
        className={`flex flex-${expanded ? "col" : "row"} gap-2 items-${
          expanded ? "start" : "center"
        }`}
      >
          <a
            onClick={() => setExpanded(!expanded)}
            className="cursor-pointer"
          >
          </a>
        <div className="w-full">{expanded ? fullLayout : (<div className="border border-bhd-blue rounded text-lg p-2 text-bhd-blue font-medium">{title}</div>)}</div>
      </div>
    )
}
export default PlainAcceptanceCriterion
